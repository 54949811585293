* {
  box-sizing: border-box;
  color: #5e6572;
}

body {
  background-color: #1c2321;
  background-size: 100%;
  margin: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

h1 {
  text-align: center;
  color: #007bff;
}

h2 {
  text-align: center;
  color: #cebacf;
}

h3 {
  text-align: center;
  color: #eaebed;
}

/*# sourceMappingURL=index.3a947f78.css.map */
