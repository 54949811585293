/* SCSS HEX */
$eerie-black: #1c2321ff;
$azure: #007bffff;
$antiflash-white: #eaebedff;
$thistle: #cebacfff;
$paynes-gray: #5e6572ff;
$powder-blue: #a9b4c2ff;

* {
    box-sizing: border-box;
    color: $paynes-gray;
  }
  
  body {
    background-color: $eerie-black;
    background-size: 100%;
    margin: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  h1 {
    text-align: center;
    color: $azure
  }

  h2 {
    text-align: center;
    color: $thistle
  }

  h3 {
    text-align: center;
    color: $antiflash-white;
  }